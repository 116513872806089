/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = {
//   aws_project_region: "us-west-2",
//   aws_cognito_identity_pool_id:
//     "us-west-2:71da1bf7-9ae5-4a97-9dfa-0cf2eb92c468",
//   aws_cognito_region: "us-west-2",
//   aws_user_pools_id: "us-west-2_8Pg4DIrID",
//   aws_user_pools_web_client_id: "1g3ng8qni32b3ehe0k0gpe2085",
//   oauth: {},
//   aws_cognito_username_attributes: [],
//   aws_cognito_social_providers: [],
//   aws_cognito_signup_attributes: ["EMAIL"],
//   aws_cognito_mfa_configuration: "OFF",
//   aws_cognito_mfa_types: ["SMS"],
//   aws_cognito_password_protection_settings: {
//     passwordPolicyMinLength: 8,
//     passwordPolicyCharacters: [],
//   },
//   aws_cognito_verification_mechanisms: ["EMAIL"],
//   aws_appsync_graphqlEndpoint:
//     "https://nymy4pbfkzasxklwikylrcn5bi.appsync-api.us-west-2.amazonaws.com/graphql",
//   aws_appsync_region: "us-west-2",
//   aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
//   aws_cloud_logic_custom: [
//     {
//       name: "StripeWebhook",
//       endpoint: "https://c16bn7w2rh.execute-api.us-west-2.amazonaws.com/prod",
//       region: "us-west-2",
//     },
//     {
//       name: "speechAceFormAPI",
//       endpoint: "https://6rnihcm3mf.execute-api.us-west-2.amazonaws.com/prod",
//       region: "us-west-2",
//     },
//   ],
//   aws_user_files_s3_bucket:
//     "phobackend5215ffae770a44dabb6d6cced3b4e3ec152407-prod",
//   aws_user_files_s3_bucket_region: "us-west-2",
//   predictions: {
//     convert: {
//       speechGenerator: {
//         region: "us-west-2",
//         proxy: false,
//         defaults: {
//           VoiceId: "Joanna",
//           LanguageCode: "en-US",
//         },
//       },
//     },
//   },
//   aws_cognito_login_mechanisms: ["EMAIL"],
//   aws_cognito_login_mechanism: ["EMAIL"],
// }

// export default awsmobile
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-west-2",
  aws_cloud_logic_custom: [
    {
      name: "StripeWebhook",
      endpoint: "https://te48axmr7c.execute-api.us-west-2.amazonaws.com/prod",
      region: "us-west-2",
    },
    {
      name: "chatGptServer",
      endpoint: "https://via1oy7bl7.execute-api.us-west-2.amazonaws.com/prod",
      region: "us-west-2",
    },
    {
      name: "speechAceFormAPI",
      endpoint: "https://y80nm1kh86.execute-api.us-west-2.amazonaws.com/prod",
      region: "us-west-2",
    },
  ],
  aws_appsync_graphqlEndpoint:
    "https://h6d3h25lfzfnbdbjnmjjn3iyxe.appsync-api.us-west-2.amazonaws.com/graphql",
  aws_appsync_region: "us-west-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_cognito_identity_pool_id:
    "us-west-2:cc372c9e-e235-4b4b-b111-aff7b1c054f2",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_7HEP6CZmZ",
  aws_user_pools_web_client_id: "at31hd22ccpjeuj0krf9aq5mp",
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket:
    "phobackend5215ffae770a44dabb6d6cced3b4e3ec171442-prod",
  aws_user_files_s3_bucket_region: "us-west-2",
  predictions: {
    convert: {
      speechGenerator: {
        region: "us-west-2",
        proxy: false,
        defaults: {
          VoiceId: "Joanna",
          LanguageCode: "en-US",
        },
      },
    },
  },
}

export default awsmobile
