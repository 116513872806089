import Auth from "@aws-amplify/auth"
import { setUser } from "./src/utils/auth"
// import "jquery/dist/jquery.min.js"
// import "popper.js/dist/popper.min"

import "./src/assets/bootstrap/css/bootstrap.min.css"
import "./src/assets/fonts/ionicons.min.css"
import "./src/assets/css/_cucstom.css"
import "./src/assets/css/Contact-Form-Clean.css"
import "./src/assets/css/Footer-Basic.css"
import "./src/assets/css/Login-Form-Clean.css"
import "./src/assets/css/Navigation-with-Button.css"
import "./src/assets/css/Registration-Form-with-Photo.css"
import "./src/assets/css/styles.css"

import Amplify from "aws-amplify"
import awsConfig from "./src/aws-exports"
Amplify.configure(awsConfig)

export const onRouteUpdate = (state, page, pages) => {
  Auth.currentAuthenticatedUser()
    .then(user => {
      const userInfo = {
        ...user.attributes,
        username: user.username,
      }
      setUser(userInfo)
    })
    .catch(err => {
      window.localStorage.setItem("gatsbyUser", null)
    })
}
