import { Auth } from "aws-amplify"

const isBrowser = typeof window !== `undefined`

export const setUser = async user => {
  window.localStorage.gatsbyUser = JSON.stringify(user)
}

const getUser = () => {
  if (window.localStorage.gatsbyUser) {
    let user = JSON.parse(window.localStorage.gatsbyUser)
    return user ? user : {}
  }
  return {}
}

export const isLoggedIn = () => {
  // try {
  //   await Auth.currentAuthenticatedUser()
  //   return true
  // } catch {
  //   return false
  // }
  if (!isBrowser) return false

  const user = getUser()
  if (user) return !!user.username
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = callback => {
  if (!isBrowser) return
  setUser({})
  callback()
}

export const isAuth = async () => {
  try {
    await Auth.currentAuthenticatedUser()
    return true
  } catch {
    return false
  }
}
